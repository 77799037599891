<template>
  <MetaBadge><time :datetime="datetime">{{ dayjs(datetime).fromNow() }}</time></MetaBadge>
</template>

<script setup>
const props = defineProps({
  datetime: String
})

const dayjs = useDayjs()
</script>

<style scoped lang="scss">

</style>
